.privacy {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-family: 'Derzky';
}

.privacy__link{
    color: #ffffff;
}

.privacy h1,
.privacy h2,
.privacy h4,
.privacy h5,
.privacy h6,
.privacy table,
.privacy p {
    text-align: left;
}

.privacy-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .privacy-table td, .privacy-table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .privacy-table th {
    background-color: #f2f2f2;
  }
  