.AlphaPage{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    /* background-color: black; */
}

.AlphaPage__main-img{
    width: 100%;
    height: auto;
    display:block;
}

.AlphaPage__video{
    width: 100%;
    height: 700px;
}

@media screen and (max-width: 767px) {
    .AlphaPage__video{
        height: 300px;
    }
}