.container_Comedy{
    padding-top: 50px;
    padding-bottom: 1px;
    margin: 0 1px;
    max-width: 1280px;
    width: 100%;
}

.comedy__text {
    text-align: left;
    padding-left: 5%;
  }

.comedy_title{
    font-size: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 3%;

}

.comedy__image-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.comedy__image{
    object-fit: cover;
    width: 100%;
    height: 400px;
    display: block;
    line-height: 0;
    margin: 0;
}

.comedy__image2{
    object-fit: cover;
    width: 50%;
    height: 400px;
    display: block;
    line-height: 0;
    margin: 0;
}


@media screen and (max-width: 780px) {
    .comedy__image-container{
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }
    .image_none{
        display: none;
    }
    .comedy__image2{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
    }
    .comedy__image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
    }
 
}