.ipad-container {
    padding-top: 50px;
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.ipad-frame {
    width: 100%;
    display: block;
}


