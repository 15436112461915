.recomend{
    max-width: 1280px;
    width: 100%;
    margin: 50px auto;

}

.recomend__title{
    font-size: 30px;
    margin-bottom: 50px;
}

.recomend__container{

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
}

.recomend__item{
    margin: 1px;
    width: 50%;
    height: 100%;
    aspect-ratio: 4/3;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
}

.recomend__img{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.recomend__overlay{
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 50%, black 100%);
    border-radius: 25px;
    position: relative;
}

.recoment__text{
    max-width: 500px;
    text-align: left;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 20px;
    font-family: 'DERZKY';
}

.recomend__first{
   /* background-image: url(../../img/portfolio/alphabank.jpg);
     background: linear-gradient(to bottom, transparent, black); */
    transition: transform 0.4s;
}

.recomend__first:hover {
    transform: scale(1.03);
}

.recomend__second{
    background-image: url(../../img/portfolio/Московский\ URBAN\ FORUM\ \ 2023-min.png);
    transition: transform 0.4s;
}

.recomend__second:hover {
    transform: scale(1.03);
}

@media screen and (max-width: 1100px) {
    .recomend__container{
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .recomend__item{
        width: 100%;
    }

    .recomend__title{
        font-size: 20px;
    }
    /*.recomend{
        margin-bottom: 10px;
    }*/
}