/* Создаем анимацию градиента */
.animated-background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Градиент 1 с анимацией */
.gradient1 {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 1.18%;
  left: 13.744%;
  border-radius: 50%;
  background: radial-gradient(circle, rgb(162, 255, 0) 0%, rgb(162, 255, 0) 100%);
  /* Уменьшили непрозрачность до 0.6, чтобы сделать градиент прозрачнее */
  animation: moveGradient1 100s linear infinite, rotateGradient1 100s linear infinite;
  transform-origin: 50% 50%;
  filter: blur(30px); /* Добавили размытие к краям */
}

/* Градиент 2 с анимацией */
.gradient2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(52, 40, 100, 1) 0%, rgba(52, 40, 100, 0) 100%);
  /*animation: moveGradient2 150s linear infinite, rotateGradient2 150s linear infinite;*/
  transform-origin: 50% 50%;
}

/* Анимации движения и вращения для Gradient1 */
@keyframes moveGradient1 {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  50% {
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  75% {
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(270deg);
  }
  100% {
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotateGradient1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* Анимации движения и вращения для Gradient2 */
@keyframes moveGradient2 {
  0%, 100% {
    top: 35.4267%;
    left: -2.17916%;
  }
  50% {
    top: 50%;
    left: -25%;
  }
}

@keyframes rotateGradient2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*конец стилей анимации*/

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* background-image: url(./img/clyksa16.gif), url(./img/clyksa16-ezgif.com-crop.gif);
  background-size: 2500px, 3000px;
  background-repeat: no-repeat, no-repeat;
  background-position-x: right, left;
  background-position-y: top, bottom; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media screen and (max-width: 767px) {
  .App {
    background-size: 580px;
  }
  
}

.App__overlay-container{
  position: relative;
  width: 100%;  /* Adjust based on your layout */
  height: 100%; /* This should match the video's height */
}

.App__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Example: a black overlay with 50% opacity */
  z-index: 1; /* Ensure it's above the video */
}

@media screen and (max-width: 700px){
  .App__bgVideo{
    top: 0;
  }
  .App{
    background-size: 800px;
  }
}

.loading-indicator{
  color: #ffffff;
  font-size: 25px;
  max-width: 850px;
  margin-top: 80px;
  /* font-family: "DERZKY"; */
  text-transform: uppercase;
  text-align: center;
  z-index: 1000; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.main-page__desktop-only {
  width: 100%;
  height: 1000px;
  position: relative; 

}

/*Стили для not_found*/
.not_found_main{
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.not_found {
  flex: 1;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin-top: 80px;
  position: relative; /* Для позиционирования псевдоэлемента */
  overflow: hidden; /* Чтобы текст "404" не выходил за границы */
  padding-left: 20px;
  white-space: nowrap;
}

/* Текст "404" через псевдоэлемент */
.not_found::before {
  content: "404"; /* Текст 404 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Центруем текст */
  font-size: 15rem; /* Размер текста */
  font-weight: bold;
  color: rgba(255, 255, 255, 0.1); /* Белый цвет с прозрачностью */
  z-index: 0; /* За основным содержимым */
}

.not_found > * {
  position: relative; /* Основное содержимое поверх текста "404" */
  z-index: 1;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  padding: 20px 0; /* Отступы сверху и снизу */

}

/*конец стилей not_found*/


@media (max-width: 1024px) {
  .main-page__desktop-only {
    display: none;
  }
  .not_found {
    margin-top: 20px;
  }
  .not_found::before {
    font-size: 8rem;
  }
  
  .metaverse_text_block{
    top: none;
  }


}

