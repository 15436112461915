.iriPage{
    /* background-color: black; */
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.iriPage__full-img{
    width: 100%;
}

.iriPage__video{
    width: 100%;
    height: 700px;
    margin: 0;
    margin-top: 0;

}

.iriPage__imgOf4{
    width: 100%;
}

.iriPage__images-1 {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 2 / 1 / 3 / 2; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }

.iriPage__about{
    font-size: 30px;
    text-align: left;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-LEFT: 150px;
}

.iriPage__content{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}
.iriPage__content_reverse{
    flex-direction: row-reverse;
}

.iriPage__content-container{
    width: 30%;
    padding: 50px;

}

.iriPage__title{
    text-transform: uppercase;
    text-align: left;
    font-size: 30px;
}

.iriPage__subtitle{
    text-align: left;
    font-size: 20px;
    font-family: "Derzky";
}

.iriPage__content-video{
    width: 30%;

}

@media screen and (max-width: 767px) {
    .iriPage__video{
        height: 300px;
    }
    .iriPage__title{
        font-size: 10px;
        height: 30px;
    }
    .iriPage__about{
        margin-top: 50px;
        margin-bottom: 50px;
        margin-LEFT: 50px;
        font-size: 20px;
    }

    .iriPage__subtitle{
        font-size: 10px;
    }
    .iriPage__content-container{
        padding: 5px;
    }
}