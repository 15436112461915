/*roblox*/

.preloader{
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(115,85,225,1) 100%);
    display: flex;
    font-size: 3rem
  }
  
.main-page__preloader-content{
    margin: auto;
  }
  
.main-page__loadingCircle{
    width: 50%;
    height: 50%;
  }
  
/*Metavers new*/

.metaverseVideo{
    width: 100%;
    height: 100%;
}

.metaverseVideo__item{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.metaverseVideo__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: 'none';
    
}

.metaverseVideo__overlay{
    width: 100%;
    height: 100%;
    top: 0; 

}

.metaverseVideo__subtitle{
    color: aliceblue;
    position: absolute;
    bottom: 15px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.metaverseVideo__project-name{
    margin: 0;
    font-weight: 900;
    font-size: 20px;
    text-align: left;
}

.metaverse_continer {
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    /*height: 728px;*/
    aspect-ratio: 16 / 9; 
    position: relative;
    overflow: hidden;
}
  
.metaverse_text_block {
    position: absolute;
    max-width: 90%; /* Оставить небольшой запас внутри блока */
    width: 100%;
    margin: 0 auto;
     /* Отступы адаптивны */
    top: 65%; /* Адаптивное позиционирование */
    transform: translateY(-50%); /* Центрирование по вертикали */
}
  
.metaverse__text {
    text-align: left;
    padding-left: 5%;
  }
  
.metaverse__about-title {
    font-size: clamp(25px, 5vw, 45px); /* Адаптивный размер шрифта */
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 3%;
  }
  
.metaverse_title_green{
    font-size: clamp(25px, 5vw, 45px); /* Адаптивный размер шрифта */
    letter-spacing: 1px;
    margin-top: 10%;
    font-weight: normal;
    color: #BAF32E;
    margin-bottom: 0;
    margin-left: 2%;
}  

.metaverse__about-text {
    font-family: 'DERZKY';
    font-size: clamp(18px, 2vw, 22px); /* Адаптивный размер текста */
    line-height: 1.25;
    margin: 0px 0px 20px;
    padding-left: 3%;
  }

.metaverse__about-statistic{
    font-family: 'DERZKY';
    font-size: clamp(12px, 2vw, 22px); /* Адаптивный размер текста */
    line-height: 1.25;
    z-index: 1;
    margin-left: 2%;
}  

.metaverse_title{
    font-size: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 5%;
}


.container_block{
    padding-top: 50px;
    padding-bottom: 1px;
    margin: 0 1px;
    max-width: 1280px;
    width: 100%;
}

.statistic_container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    
    flex-wrap: wrap;
    justify-content: center;

}

.statistic_item{
    border-radius: 30px;
    border: 2px solid #BAF32E;
    margin: 10px;
    position: relative;
}

.statistic_size_one{
    height: 360px; /* Почти половина высоты блока 1 */
    flex: 1;
    max-width: 620px;
    display: flex; /* Включаем Flexbox */
    flex-direction: column; /* Располагаем элементы вертикально */
    justify-content: center; 
    text-align: center;

}

.statistic_size_two{
    height: 210px; /* Почти половина высоты блока 1 */
    max-width: 620px;
    width: 100%;
}

.statistic_size_tree{
    width: 100%;
    height: 600px;
    position: relative;
    overflow: visible;

}

.statistic_container_left{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 620px;
    width: 100%;
    max-width: 620px;
}

.statistic_container_right{
    flex: 1;
    display: flex;
    width: 40%;
    margin: 0;
    min-width: 620px;
    width: 100%;
    max-width: 620px;
   
}

.page_style{
    display: block; /* Убираем лишние отступы */
    max-width: 100%; /* Картинка уменьшится вместе с контейнером */
    max-height: 100%; /* Не выйдет за пределы контейнера */
    margin: auto; /* Центрируем по горизонтали и вертикали */
    position: absolute; /* Для точного позиционирования */
    top: 50%; /* Сдвигаем вниз на 50% */
    left: 50%; /* Сдвигаем вправо на 50% */
    transform: translate(-50%, -50%); /* Возвращаем в центр */
}

.border_image {
    position: absolute; /* Позволяет привязать к границе */
    width: 100px; /* Ширина картинки */
    height: auto; /* Сохраняем пропорции */
    top: 45%; /* Привязка к верхней границе */
    left: 100%; /* Центрируем по горизонтали */
    transform: translateX(-50%); /* Выравниваем по центру */
    z-index: 1000;
}

.thesis_conteiner{
    margin: 0 auto;
    display: flex; /* Используем Flexbox */
    flex-direction: column; /* Элементы располагаются вертикально */
    align-items: center; /* Центрируем элементы по горизонтали */
    gap: 0; /* Убираем зазоры между блоками */
    line-height: 0;
    position: relative;

}

.thesis_border_image{
    position: absolute; /* Позволяет привязать к границе */
    width: 300px; /* Ширина картинки */
    height: auto; /* Сохраняем пропорции */
    top: -16%; /* Привязка к верхней границе */
    left: 70%; /* Центрируем по горизонтали */
    transform: translateX(-50%); /* Выравниваем по центру */
    z-index: 1000;
    -webkit-animation: rotating 20s linear infinite;
          animation: rotating 20s linear infinite;
}

.thesis_inner_block{
    position: relative;
    display: flex;
    flex-direction: column; /* Элементы располагаются вертикально */
    justify-content: center; /* Центрируем текст по вертикали */
    align-items: flex-start; /* Выравниваем текст по левому краю */
    width: 100%; /* Растягиваем содержимое по ширине */
    height: auto; /* Высота зависит от содержимого */
    gap: 20px; /* Расстояние между текстовыми блоками */
}

.back_img {
    width: 100%; /* Ширина изображения заполнит контейнер */
    height: auto; /* Высота изображения определяется автоматически */
    display: block; /* Изображение занимает всю ширину контейнера */
    z-index: 0; /* Фон позади остальных элементов */
  }
  
.star_img {
    position: absolute; /* Для точного позиционирования звезды */
    left: 3%; /* Смещение от правого края */
    width: 70px; /* Размер изображения звезды */
    height: auto;
    z-index: 2; /* Над фоном, но под текстом */
    margin: auto 5px ;

}

.metaverse__about{
    position: absolute; /* Для точного позиционирования звезды */
    left: 5%; /* Смещение от правого края */
    font-family: 'DERZKY';
    font-size: clamp(12px, 2vw, 22px); /* Адаптивный размер текста */
    line-height: 1.25;
    z-index: 1;
    margin-left: 2%;
}  


.border{
    margin-top: 50px;
    border: 2px solid #f8f8f8;
    padding-top: 5%;
    padding-bottom: 5%;
}

.marcketing__items{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;


}

.marcketing__item{
    position: relative;
    width: 540px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;

}

.about__items{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;

}

.about__item{
    position: relative;
    width: 540px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    justify-content: space-between;
}

.marcketing__item1{
    width: 100%;
}


.green_star{
    width: 65px; /* Размер изображения звезды */
    height: auto;

}

.white_star{
    width: 65px; /* Размер изображения звезды */
    height: auto;

}

.marcketing_text {
    font-family: 'DERZKY';
    font-size: clamp(18px, 2vw, 22px); /* Адаптивный размер текста */
    line-height: 1.25;
    z-index: 1;
    margin-left: 0;
    padding-left: 0;
}

/*О нас*/
.about_title{
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: normal;


    color: #BAF32E;
}

.about_text{
    font-family: 'DERZKY';
    font-size: clamp(18px, 2vw, 24px); /* Адаптивный размер текста */
    line-height: 1.25;
    z-index: 1;

    margin: 0;
    
}


.magnum_logo-img{
    position: absolute;
    width: 321px;
    height: 61px;
    top: 7%;
    left: 7%;

}

.contact_form{
    max-width: 1280px;
    width: 100%;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;

    background-image: url(../../src/img/Cat.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100%;
    /*height: 728px;*/
    aspect-ratio: 16 / 9; 
    position: relative;
    overflow: hidden;
}

.contact_form_text_block {
    padding-top: 10%;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    overflow: hidden;
}

.contact-form__buttons {
    margin-left: 5%; /* Убираем автоматическое центрирование */
    background-color: #BAF32E;
    border-radius: 25px;
    color: #000000;
    border: none;
    height: 60px;
    max-width: 309px;
    width: 100%;
    display: flex; /* Используем flex для работы с текстом внутри */
    justify-content: center; /* Горизонтальное выравнивание текста влево */
    align-items: center; /* Вертикальное центрирование текста */
    text-decoration: none; /* Убираем подчёркивание */
    font-size: clamp(12px, 2vw, 18px);/* Размер шрифта */
    font-weight: bold; /* Жирность текста */
    margin-top: 20px; /* Добавляем небольшой отступ сверху для эстетики */
    text-align: center; /* На всякий случай текст выравнивается влево */
    cursor: pointer; /* Изменяем курсор на "руку" */
}

.contact-form__buttons {
    transition: transform 0.2s linear;
}

.contact-form__buttons:hover {
    transform: scale(1.07);
}

.marcketing__apmet {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
}

.indent{
    padding-left: 50px;
}

.apmet {
    max-width: 100%; /* адаптивность картинки */
    height: auto;

}

.apmet_text{
    font-family: 'DERZKY';
    font-size: clamp(18px, 2vw, 24px); /* Адаптивный размер текста */
    line-height: 1.25;
    z-index: 1;
    margin: 0;
    padding-top: 3%;
        
}

.height_level{
    height: 220px;
}


@media (max-width: 1263px) {
    .marcketing__items{
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .main-page__desktop-only {
      display: none;
    }
    .not_found {
      margin-top: 20px;
    }
    .not_found::before {
      font-size: 8rem;
    }
    
    .metaverse_text_block{
        top: 40%;
    } 
    .star_img {
        width: 50px;
    }

    .thesis_border_image{
        width: 200px; 
    }

    .magnum_logo-img{
        top:2%;
        width: 185px;
        height: 36px;
    }
    .marcketing__items{
        justify-content: center;
    }

}

@media screen and (max-width: 767px) {

    .metaverse_text_block{
        top: 65%;
    } 
    .statistic_container_right,
    .statistic_container_left{
     
        max-width: 420px;
        min-width: 420px;
    }
    .statistic_size_one{
        height: 240px;
    }

    .statistic_size_two{
        height: 140px;
    }

    .statistic_size_tree{
        height: 450px;
        overflow: hidden;
    }

    .star_img {
        width: 20px;
    }
    
    .thesis_border_image{
        display: none; 
    }

    .magnum_logo-img{
        display: none;
    }
   
    .contact-form__buttons{
        height: 50px;
        width: 200px;
    }
    
}