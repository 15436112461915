.contact-form{
  height: 700px;
  padding-top: 60px;
  width: 100%;
  display: flex;
  position: relative;

  /* background-image: url(../../img/background.png); */
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 1280px;
  /* background-color: #684FC9; */

}

.contact-form__container{
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  max-width: 675px;
  margin: 0 auto;
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
}

.contact-form__title{
  font-size: 25px;
  margin-bottom: 20px;
}

.contact-form__form{
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 30px;
  padding: 30px;
  gap: 8px;
  /* background-color: aquamarine; */
}

.contact-form__subtitle{
  margin-bottom: 50px;
}

.contact-form__input{
  height: 40px;
  padding: 10px;
  padding-left: 20px;
  font-size: 16px;
  background-color: #684FC9;
  border-radius: 10px;
  border: none;
}

.contact-form__input::placeholder{
  color: #a495df;
}

.contact-form__button{
  background-color: #BAF32E;
  border-radius: 50px;
  color: #000000;
  border: none;
  height: 80px;
  max-width: 455px;
  width: 100%;
  margin: 10px auto;
}

.contact-form__button{
  transition: transform 0.2s linear;
}

.contact-form__button:hover{
  transform: scale(1.07);
}

.contact-form__agreementSign{
  font-family: "Derzky";
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .contact-form__title{
    font-size: 20px;
  }
  .contact-form__input{
    height: 30px;
    padding: 5px;
    padding-left: 10px;
  }

  .contact-form__button{
    height: 58px;
    margin: 20px auto;
    width: 185px;
    font-size: 16px;
    width: 205px;
  }

  /* .contact-form{
    height: 75vh;
  } */

  .contact-form__container{
    max-width: 500px;
  }
  .contact-form__form{
    height: 300px;
  }

  .contact-form{
    height: 500px;
  }
  .contact-form__subtitle{
    font-size: 15px;
  }
  .contact-form__agreementSign{
    font-size: 10px;
  }
}