
.simpozium_contaner {
    max-width: 1280px;
    width: 100%;
    margin: 50px auto 0px;
    overflow: hidden;

  }

.grid-container{
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 1fr 1fr 1fr;
    grid-template-areas:
    "header header"
    "frame1 frame2"
    "frame1 frame3"
    "frame1 frame4";

}

.item1{
    grid-area: header;   
    text-align:start;
    padding-top: 20px;
    align-items: center;
            
}

.item2{
    grid-area: frame1;   
    
   
}

.item3{
    grid-area: frame2;    
  
}

.item4{
    grid-area: frame3;   

}

.item5{
    grid-area: frame4; 

}

.img_style{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.simpozium_title {
    font-size: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 3%;
}

@media screen and (max-width: 767px) {
    .grid-container{
        display: flex;
        flex-direction: column;
    }
    
}