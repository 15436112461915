.interactiveVideo{
    width: 100%;
    height: 100%;

}

.interactiveVideo__item{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.interactiveVideo__image{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
    display: 'none';
    
}



.interactiveVideo__overlay{
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 50%, black 100%);
    position: absolute;
    top: 0; 
    border-radius: 30px;

}

.interactiveVideo__subtitle{
    color: aliceblue;
    position: absolute;
    bottom: 15px;
    left: 20px;
    font-family: 'DERZKY';
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.interactiveVideo__project-name{
    margin: 0;
    font-weight: 900;
    font-size: 20px;
    text-align: left;
}

.interactiveVideo__project-hash{
    margin: 0;
    text-align: left;
}