.button-container {
    position: relative;
  }
  
  .uslugi_button {
    border: 2px solid #baf32e;
    background-color: transparent;
    border-radius: 30px;
    color: #ffffff;
    padding: 10px 20px; 
    cursor: pointer;
    display: inline-flex; 
    align-items: center; 
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.2s linear; /* Добавляем transform в transition */
    transform: translateY(-50px); /* Смещаем кнопку вверх */
  }
  
  .uslugi_button:hover {
    transform: translateY(-50px) scale(1.07); /* Объединяем translateY и scale */
  }