.SuperCity{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    /* background-color: black; */
}

.SuperCity__main-img{
    width: 100%;
    height: auto;
    display:block;
}

.SuperCity__video{
    width: 100%;
    height: 700px;
}

.SuperCity__imgOf4{
    width: 100%;
    object-fit: cover;
    aspect-ratio: 5/3;
}

.SuperCity__images-1 {
    padding-top: 150px;
    background: linear-gradient(82deg, rgba(41,40,116,1) 0%, rgba(105,44,165,1) 65%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }

@media screen and (max-width: 767px) {
    .SuperCity__video{
        height: 300px;
    }
}