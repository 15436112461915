.mts{
    max-width: 1280px;
    margin: 0 auto;
    /* background-color: black; */
}

.mts__container{
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
}
.mts__mainImg{
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 0;

}

.mts__youtube-1{
    width: 100%;
    height: 700px;
}

.mts__imgOf4{
    width: 100%;
}

.mts__images-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 2 / 1 / 3 / 2; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }

    .video{
        width: 100%;
        display: flex;
        gap: 10px;
    }
    .video__container{
        margin-top: 50px;
        width: 50%;
    }
    .video-small{
        width: 100%;
    }
    .video__title{
        font-size: 25px;
        text-transform: uppercase;
        max-width: 300px;
        text-align: left;
    }


@media screen and (max-width: 767px) {
    .mts__youtube-1{
        height: 300px;
    }
    .video__title{
        font-size: 10px;
        height: 30px;
    }
}