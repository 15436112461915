.services-expand{
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.mobile-only{
  display: none;
}

.content {
    margin: 100px auto;
    max-width: 1280px !important;
    width: 100%;
    .content__card{
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .content__subtitle{     
        max-width: 400px;
        font-size: 20px;
    }
    .contemt__image{
        height: 500px;
        width: 813px;
        border-radius: 30px;
    }
    .card__title-animated{
        transition: color 0.2s linear;
    }
    .card__title-animated:hover {
        color: #BAF32E;
    }
  }
  
  .list-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .list-group-item {
    border-radius: 30px !important;
    background-color: rgba(0, 0, 0, 0);

  }
  
  /* BEST - JS calculated height */
  .list-group-item {
    padding: 30px;
    .card-title {
      cursor: pointer;
      text-align: left;
      text-transform: uppercase;
    }
    .card-collapse {
      overflow: hidden;
      transition: height 0.3s ease-out;
    }
    // .card-body {
    //   border: none !important; // why do we need this? (doesn't seem to change anything)
    // }
    p{
        text-align: left;
    }
    .card__container{
        display: flex;
        justify-content: space-between;
        gap: 20PX;
    }
    .content__subtitle{
        font-family: "Derzky";
    }

  }
  
  .is-expanded {
    background-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    outline: 2px solid #BAF32E !important;
    // border: 2px solid red !important;
    // border-bottom: 2px solid red !important;
    // radius: 10px;
    background-image: url(../../img//half_star_decor.png);
    background-repeat: no-repeat;
    background-position: top 0 left 10%;
  }
  
  .card-title_colored {
    color: #BAF32E !important;
  }
  

.service-expand__circle{
    width: 330px;
    animation: rotating 20s linear infinite;
}

.desktop-only {
  height: 600px;
}

@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .services-expand__mainImg{
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .services-expand__mobile-only{
    display: none;
    font-size: 55px;
    margin-bottom: 30px;
  }

  .services-exand__servises-list{
    // margin-top: -100px;
    width: 100%;
    height: 600px;
  }


  @media screen  and (max-width: 1000px){
    .contemt__image{
        width: 250px !important;
        height: auto !important;
    }
  }
  @media screen  and (max-width: 700px){
    .content__card{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .service-expand__circle{
        width: 150px;
    }
  }

  @media (max-width: 1024px) {
    .desktop-only {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .services-expand__mobile-only{
      display: block;
    }
  }

  @media (max-width: 1225px) {
    .services-exand__servises-list{
      margin-top: 0px;
      width: '100%';
      height: 550px;
    }
  }

  @media (max-width: 941px) {
    .services-exand__servises-list{
      margin-top: 0px;
      width: '100%';
      height: 400px;
    }
  }
  @media (max-width: 689px) {
    .services-exand__servises-list{
      display: none;
      margin-top: 0px;
      width: '100%';
      height: 300px;
    }
  }
  @media (max-width: 509px) {
    .services-exand__servises-list{
      margin-top: 0px;
      width: '100%';
      height: 250px;
    }
    .mobile-only{
      display: block;
    }
  }
  @media (max-width: 435px) {
    .services-exand__servises-list{
      margin-top: 0px;
      width: '100%';
      height: 220px;
    }
    .services-expand__mobile-only{
      font-size: 35px;
      margin-bottom: 30px;
    }
  }