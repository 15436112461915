.header{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0px;
    /* position: fixed; */
    /* top: 0;
    background-color: black;
    z-index: 10; */
    /* background-image: url(../../img/clyksa16.gif);
    background-position: 0 0;
    background-size: 100%; */
}

.header__container{
    position: relative;
    display: flex;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.header__img-logo{
    width: 120px;
    height: 120px;
    margin-top: 50px;
    cursor: pointer;
}

.header__list{
    padding: 0;
    margin-top: 70px;
    display: flex;
    margin: 0;
    gap: 30px;
    list-style: none;
    position: absolute;
    left: 50%;
    margin-top: 70px;
    transform: translate(-50%, 0);
}

.header__link{
    text-decoration: none;
    color: #ffffff;
    transition: color 0.2s linear;
}

.header__link:hover{
    color: #BAF32E;
}

.header__scale-link{
    transition: transform 0.2s linear;
}

.header__scale-link:hover {
    transform: scale(1.07);
}

@media screen and (max-width: 767px){
    .header{
        margin: 0 auto 20px;
    }
}