.portfolio {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.portfolio__item {
  background-color: rgb(0, 0, 0);
  border-radius: 30px;
  position: relative;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "area1 area1 area1 area2 area2 area2 area3 area3"
    "area1 area1 area1 area2 area2 area2 area3 area3"
    "area1 area1 area1 area2 area2 area2 area3 area3"
    "area4 area4 area4 area4 area4 area4 area3 area3"
    "area4 area4 area4 area4 area4 area4 area3 area3"
    "area4 area4 area4 area4 area4 area4 area7 area7"
    "area4 area4 area4 area4 area4 area4 area7 area7"
    "area5 area5 area5 area6 area6 area6 area7 area7"
    "area5 area5 area5 area6 area6 area6 area7 area7"
    "area5 area5 area5 area6 area6 area6 area7 area7";
}

.container2 {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "area10 area10 area9 area9 area9 area8 area8 area8"
    "area10 area10 area9 area9 area9 area8 area8 area8"
    "area10 area10 area9 area9 area9 area8 area8 area8"
    "area10 area10 area11 area11 area11 area11 area11 area11"
    "area10 area10 area11 area11 area11 area11 area11 area11"
    "area14 area14 area11 area11 area11 area11 area11 area11"
    "area14 area14 area11 area11 area11 area11 area11 area11"
    "area14 area14 area13 area13 area13 area12 area12 area12"
    "area14 area14 area13 area13 area13 area12 area12 area12"
    "area14 area14 area13 area13 area13 area12 area12 area12";
}

.container3 {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    "area15 area15 area15 area16 area16 area16 area17 area17"
    "area15 area15 area15 area16 area16 area16 area17 area17"
    "area15 area15 area15 area16 area16 area16 area17 area17"
    "area18 area18 area18 area18 area18 area18 area17 area17"
    "area18 area18 area18 area18 area18 area18 area17 area17"
    "area18 area18 area18 area18 area18 area18 area21 area21"
    "area18 area18 area18 area18 area18 area18 area21 area21"
    "area19 area19 area19 area20 area20 area20 area21 area21"
    "area19 area19 area19 area20 area20 area20 area21 area21"
    "area19 area19 area19 area20 area20 area20 area21 area21";
}


.area {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.4s;
  /* background-size: 800px; */
  border-radius: 30px;
}

.area:hover {
  transform: scale(1.03);
}

.den-predprinimatelya {
  grid-area: area1;
}

.brainstorm {
  grid-area: area2;
}

.alfa-digital {
  grid-area: area3;
}

.moscow-urban-forum {
  grid-area: area7;
}

.zolotyye-khity {
  grid-area: area6;
}

.mts-cyber-sation {
  grid-area: area5;
}

.digital-berozy {
  grid-area: area4;
  aspect-ratio: 5/3;
}

.sto-let-rasul-gamzatov {
  grid-area: area8;
}

.most-creative-awards {
  grid-area: area9;
}

.basis-devops-conf {
  grid-area: area10;
}

.ostrovok-high-load {
  grid-area: area14;
}

.super-city-roblox {
  grid-area: area13;
}

.den-znaniy-v-kremle {
  grid-area: area12;
}

.pyaterochka-roblox {
  grid-area: area11;
  aspect-ratio: 5/3;
}

.udachnyye-pesni{
  grid-area: area15;
  white-space: pre-line;
}

.simpozium{
  grid-area: area16;
  white-space: pre-line;
}

.comedy{
  grid-area: area17;
}
.nornickel{
  grid-area: area18;
  aspect-ratio: 5/3;
}
.forum-edo{
  grid-area: area19;
}

.ambi{
  grid-area: area20;
  white-space: pre-line;
}

.zhkkh{
  grid-area: area21;
}

.overlay {
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  text-align: center;
  transition: opacity 0.4s;
}

.portfolio__item:hover .overlay {
  opacity: 1;
  /* display: flex; Change display property to flex to make it visible */
}

@media screen and (max-width: 767px) {
  .container {
    display: flex; /* Use flexbox instead of grid */
    flex-direction: column; /* Stack child elements in a column */
    margin-top: 10px;
    height: 100%;
    gap: 20px; /* Maintain the gap between elements */
  }

  .container2 {
    display: flex; /* Use flexbox instead of grid */
    flex-direction: column; /* Stack child elements in a column */
    margin-top: 10px;
    height: 100%;
    gap: 20px; /* Maintain the gap between elements */
  }

  .container3 {
    display: flex; /* Use flexbox instead of grid */
    flex-direction: column; /* Stack child elements in a column */
    margin-top: 10px;
    height: 100%;
    gap: 20px; /* Maintain the gap between elements */
  }
  
  /* You can assign classes or ids to your individual areas if they need specific styling */
  .den-predprinimatelya, .brainstorm, .alfa-digital, .digital-berozy, .mts-cyber-sation, .zolotyye-khity, .moscow-urban-forum {
    width: 100%; /* Each area takes the full width */
    /* Add additional styling as needed */
    height: 300px;
    border-radius: 30px;
  }

  .sto-let-rasul-gamzatov, .most-creative-awards, .basis-devops-conf, .pyaterochka-roblox, .den-znaniy-v-kremle, .super-city-roblox, .ostrovok-high-load {
    width: 100%; /* Each area takes the full width */
    /* Add additional styling as needed */
    height: 300px;
    border-radius: 30px;
  }

  .udachnyye-pesni, .simpozium, .comedy, .nornickel, .forum-edo, .ambi, .zhkkh {
    width: 100%; /* Each area takes the full width */
    /* Add additional styling as needed */
    height: 300px;
    border-radius: 30px;
  }

}
