.footer{
  /* overflow: hidden; */
  /* background-                                                                                                                                          image: url(../../img/background.png); */
  /* object-fit: cover; */
  background-repeat: no-repeat;
  position: relative;
  background-size: 2000px;
  max-width: 1280px;
  width: 100%;
  margin: 50px auto 0;
  background-image: url(../../img/Footer.webp);
  background-repeat: no-repeat;
  background-position: top right ;
  background-size: 100%;
  height: 320px;

}

.footer__container{
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
 box-sizing: border-box;

}

.footer__tel{
  text-decoration: none;
  color: white
}

.footer__tel:hover{
  color: #BAF32E;
}

.footer__info{
  margin: 0;
  margin-bottom: 10px;
}

.footer__img-logo{
  width: 120px;
  height: 120px;
  padding-top: 55px;
}

.footer__contacts{
  padding-top: 70px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.footer_sosial{
  width: 50%;
  padding-bottom: 30px;
  box-sizing: border-box;
}
.footer__decor{
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  right: 0;
}

.footer__tel{
  cursor: pointer;
}

.nav_bar{
  width: 400px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;     
  align-items: center;  

}


.social_media{
  display: flex;            
  justify-content: center;    
  align-items: center;               
  margin: 0 10px;     
  width: 50px;
  height: 50px;
}

.social_media svg {
  width: 100%;             
  height: 100%;
  fill: #ffffff;          
  transition: all 0.3s ease;
}

.social_media:hover svg {
  fill: #BAF32E;           
  transform: scale(1.2);   
}

.nav_bar a .rutube{
  background-image: '../img/rutube.svg';
}


@media screen and (max-width: 1200px) {
  .footer{
    background-position: top 20px right 0px ;
    background-size: 100%;
  }
}

@media screen and (max-width: 767px) {
  .footer__info{
    font-size: 15px;
  }

  .footer{
    background-image: url(../../img/Mobile_footer.webp);
    background-position: top 400px right -50px ;
    background-size: 500px;
    height: 520px;
    z-index: 1000;
  }

  .footer__container{
    flex-direction: column;
    align-items: start;

  }
  .footer_sosial{
    width: 100%;
    overflow: hidden;
  }

  .footer__contacts{
    padding-top: 0;
  }

  .social_media{
    margin: 0;
  }

  .nav_bar{
    justify-content: space-between;
    gap: 1%;
    width: 100%;
  }

}