.services{
    padding-top: 50px;
    margin: 0 auto;
    /* background-color: black */
    /* border: solid red; */
}

.services__container{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    height: 1000px;
}

.services__item{
    display: flex;
    width: 50%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 550px;
    background-position: top 60% left 50%;
    position: relative;
    cursor: pointer;
}
.services__item-titleimg {
    display: flex;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 30%;
    width: 80%; 
    max-width: 85%; 
    z-index: 0;
    transition: transform 0.3s ease; /* Smooth scaling transition */
    transition: opacity 0.5s ease;
}

.services__item-background {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px; 
    height: 300px; 
    z-index: 0;
    transition: transform 0.3s ease; /* Smooth scaling transition */
    transition: opacity 0.5s ease;
}

.services__item-arrow {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10%; 
    width: 10%; 
    object-fit: contain;
    z-index: 0;
    transition: transform 0.3s ease; /* Smooth scaling transition */
    transition: opacity 0.5s ease;
}

.services__item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0); /* Semi-transparent overlay */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.services__title{
    color: #ffffff;
    font-size: 30px;
    max-width: 850px;
    margin: 30px auto 60px;
    /* font-family: "DERZKY"; */
    text-transform: uppercase;
}

.services__item:hover .services__item-overlay {
    opacity: 1;
}

.services__item-title {
    transition: opacity 0.5s ease;
    z-index: 1;
}

.services__item:hover .services__item-title {
    opacity: 0;
}
.services__item:hover .services__item-titleimg {
    opacity: 0;
}

.services__item1{
    background-image: url(../../img/cube_site.webp);
    background-size: cover;

}

.services__item2{
    background-image: url(../../img/section2.webp);
    background-size: cover;

}

.services__item3{
    background-image: url(../../img/section3.webp);
    background-size: cover;
}

.services__item4{
    background-image: url(../../img/render7.webp);
    background-size: cover;
}

.services__item5{
    background-image: url(../../img/XR_site.webp);
    background-size: cover;
}


.services__item6{
    background-image: url(../../img/code_site.webp);
    background-size: cover;
}


.services__item-title{
    margin: auto;
    font-size: 55px;
}

.services__circle-decor{
    margin-bottom: 50px;
    width: 218px;
    animation: spin 20s infinite linear;
    display: none;
}

.services__list{
    padding: 0;
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-family: 'DERZKY';
    font-size: 20px;
    z-index: 1;
}

#custom-cursor {
    position: fixed;
    width: 80px; /* Размер курсора */
    height: 80px; /* Размер курсора */
    background-image: url('../../img/cursor.png'); /* Замените на путь к вашему изображению курсора */
    background-size: contain; /* Сохраняем пропорции изображения */
    background-position: center; /* Центрирование изображения */
    background-repeat: no-repeat; /* Запрет на повтор изображения */
    border-radius: 50%; /* Сохраняем округлую форму */
    pointer-events: none;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center center;
    will-change: transform, left, top;
    transition: transform 0.3s ease;
    z-index: 1000;
    display: flex; /* Используем flex для центрирования текста */
    align-items: center; /* Вертикальное центрирование */
    justify-content: center; /* Горизонтальное центрирование */
    left: var(--cursor-x); /* Используем CSS-переменные для координат */
    top: var(--cursor-y);
}

.cursor-text {
    color: white;
    font-size: 10px; 
    text-align: center; 
    line-height: 1.2;
    white-space: nowrap; 
    transform: translate(4px, 1px);
}

.services__item {
    cursor: none;
}

.services__item:hover ~ #custom-cursor {
    transform: scale(1) translate(-50%, -50%);
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1100px) {
    .services__item{
        background-size: 500px;
    }

    .services__item-title{
        font-size: 35px;
    }
}

@media screen and (max-width: 767px) {
    .services__item{
        width: 100%;
        background-size: 300px;
        background-position: top 60% left 50%;
    }

    .services__item-title{
        font-size: 25px;
    }

    .services__title{
        font-size: 20px;
    }

    .services__circle-decor{
        display: block;
        margin: 20px auto 50px;
    }

    .services__list{
        max-width: 250px;
        font-size: 10px;
    }
    .services__container{
        height: 150vh;
    }

}

.placeholder {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    z-index: 0;
    transition: transform 0.3s ease, opacity 0.5s ease; /* Объединённый transition */
    background-image: url('../../img/loading-circle.png');
    background-size: contain; /* Сохраняем пропорции изображения */
    background-position: center; /* Центрирование изображения */
    background-repeat: no-repeat; /* Запрет на повтор изображения */
    border-radius: 50%; /* Сохраняем округлую форму */
    animation: rotate 8s linear infinite; /* Анимация вращения */
}

@keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg); /* Начальное положение */
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg); /* Полный оборот */
    }
  }