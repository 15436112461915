.container_Nornickel{
    padding-top: 50px;
    padding-bottom: 1px;
    margin: 0 1px;
    max-width: 1280px;
    width: 100%;
}

.nornickel__text {
    text-align: left;
    padding-left: 5%;
  }

.nornickel_title{
    font-size: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 3%;

}

.nornickel__image-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.nornickel__image{
    object-fit: cover;
    width: 50%;
    height: 400px;
    display: block;
    line-height: 0;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .nornickel__image-container{
        flex-direction: column;
    }
    .nornickel__image{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}