.test{
    /* border: solid white;
    border-radius: 30px; */
    min-height: 520px;
    /* border-bottom: 2px solid white; */
    width: 100px;

}

@media screen and (max-width: 767px){
    .test{
        /* border: solid white;
        border-radius: 30px; */
        min-height: 99px;
    
    }
}