.steps{
    margin-top: 100px;
}

.steps__container{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.steps__title{
    margin-bottom: 50px;
    text-transform: uppercase;
}

.steps__items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
    background-color: transparent;
}

.steps__item{
    border-radius: 30px;
    border: 2px solid #BAF32E;
    width: 200px;
    height: 250px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    align-content: center;
    gap: 15px;
}

.steps__number{
    margin: 0;
    margin-top: 30%;
    /* font-weight: 900; */
    font-size: 1.5rem;
}

.steps__step{
    margin: 0;
    font-family: "Derzky";
}

@media screen  and (max-width: 700px){
    .steps__item{
        width: 300px;
        height: 100px;
    }
    .steps__number{
        margin-top: 2%;
    }
}