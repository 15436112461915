.edo_block{
    padding-top: 50px;
    padding-bottom: 1px;
    margin: 0 1px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1280px;
}

.edo_text {
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }

.edo_title{
    font-size: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 3%;
}

.edo_container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    max-width: 1280px;
    justify-content: center;

}

.edo_container_left{
    display: flex;
    flex-direction: row;
    width: 50%;
    padding: 0;
 
   /* min-width: 620px;
    max-width: 620px;*/
    

    
}

.edo_item{
    border-radius: 30px;
    border: 1px solid #BAF32E;
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 10px;
    margin-bottom: 0;
    max-width: 620px;

}

.edo_title_green{
    text-transform: uppercase;
    color: #BAF32E;
    font-size: clamp(8px, 5vw, 35px);
    letter-spacing: 1px;
    font-weight: normal;
    margin: 0;

}  

.edo__about-statistic{
    font-family: 'DERZKY';
    font-size: clamp(10px, 3vw, 22px); /* Адаптивный размер текста */
    line-height: 1.25;
    margin-top: 10px;
    margin-bottom: 0;
}

.edo_container_right{
    display: flex;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;
}

.edo_item2{
    border-radius: 30px;
    border: 1px solid #BAF32E;
    width: 98%;
    height: 194px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 10px;
    margin-bottom: 0;
}

@media screen and (max-width: 1090px) {
    .edo_item{
        width: 100%;
        height: 200px;  
        border-radius: 20px;
        margin: 5px;
    }
    .edo_item2{
        width: 97%;
        height: 100px;  
        border-radius: 20px;
        margin: 5px;
    }

    .edo_container_left{
        width: 100%;
    }
    .edo_container_right{
        width: 100%;
    }
   
}

@media screen and (max-width: 780px) {
    .edo_item{
        width: 100%;
        height: 200px;  
        border-radius: 20px;
        margin: 5px;
    }
    .edo_item2{
        width: 97%;
        height: 100px;  
        border-radius: 20px;
        margin: 5px;
    }

    .edo_container_left{
        width: 100%;
    }
    .edo_container_right{
        width: 100%;
    }
    
   
}